import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
} from "@ionic/react";
import React from "react";

const Explore = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="text-2xl lg:text-3xl">Explore</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="flex h-1/2 items-center justify-center px-10 text-center text-4xl">
          Explore Page Coming Soon!
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Explore;
